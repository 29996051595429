<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Hoja de impresión"
      subtitle="| Hoja de impresión"
      class="heading-block"
    />    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
					<b-row class="mt-2">
						<b-col sm="2" class="mt-2" offset-sm="2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Fecha recepción ventas:
                </template>
                  <b-form-datepicker
                    id="datepicker"
                    v-model="quoteInfo.service_order.fecha_recepcion"
                    placeholder="Fecha de recepción" 
                    class="mb-2"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    disabled
                    ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Fecha recepción producción:
                </template>
                  <b-form-datepicker
                        id="datepicker"
                        v-model="quoteInfo.production_order.fecha_recepcion"
                        placeholder="Fecha de recepción" 
                        class="mb-2"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        disabled
                        ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Orden de compra:
                </template>
                  <b-form-input
                    v-b-tooltip.hover title="Escriba la orden de compra"
                    v-model="quoteInfo.service_order.orden_de_compra"
                    size="sm"
                    disabled
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Orden de producción:
                </template>
                <b-form-input
                  v-b-tooltip.hover title="Escriba la orden de producción"
                  v-model="quoteInfo.service_order.orden_de_produccion"
                  size="sm"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
					</b-row>
          <b-row class="mt-2">
          </b-row>
					<b-row class="mt-2">
						<b-col sm="2" class="mt-2" offset-sm="2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    # de cotización:
                </template>
                  <b-form-input
                    v-model="quoteInfo.id"
                    disabled
                    size="sm"
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Hora recepción ventas:
                </template>
                  <b-form-input
                    v-model="quoteInfo.service_order.hora_recepcion"
                    size="sm"
                    disabled
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Hora recepción producción:
                </template>
                <b-form-input
                    v-model="quoteInfo.production_order.hora_recepcion"
                    size="sm"
                    disabled
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Fecha de entrega:
                </template>
                <b-form-datepicker
                      id="datepicker"
                      v-model="quoteInfo.service_order.fecha_entrega"
                      placeholder="Fecha de entrega" 
                      class="mb-2"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      disabled
                      >
                    </b-form-datepicker>
              </b-form-group>
            </b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col sm="4" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                    Nombre del cliente:
                </template>
                  <b-form-input
                    v-model="quoteInfo.client.nombre_comercial"
                    disabled
                    size="sm"
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                  Salida almacén:
                </template>
                <select class="form-control" v-model="quoteInfo.production_order.warehouse_id" disabled>
                  <option :value="data.id" v-for="(data) in almacenesList" :key="data.id">
                    {{ data.name }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
            <b-col sm="4" class="mt-2">
              <b-form-group label-for="no_cliente">
                <template #label>
                  Sucursal:
                </template>
                <select class="form-control" v-model="quoteInfo.service_order.branch_id" disabled>
                  <option :value="data.id" v-for="(data) in sucursalesList" :key="data.id">
                    {{ data.name }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
					</b-row>
          <b-row>
            <b-col sm="12" class="mt-4">
              <!-- === PRODUCTO TERMINADO === -->
              <b-row class="ml-4 mt-12">
                <div class="container">
                  <div class="row">
                    <div class="col-5" id="linea">
                      <hr>
                    </div>
                    <div class="col-2" id="titulo">
                      <p>Producto terminado</p>
                    </div>
                    <div class="col-5" id="linea">
                      <hr>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row class="ml-4 mt-12">
                <b-col sm="12">
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1" v-for="(item_producto_terminado, index_Q_P_T) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button id="button_accordion" block v-b-toggle="'accordion-producto-terminado'+index_Q_P_T" variant="info">Producto Terminado: {{item_producto_terminado.no_parte}}</b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-producto-terminado'+index_Q_P_T" accordion="my-accordion-producto-terminado" role="tabpanel">
                        <b-card-body>
                          <br><br>
                          <b-row class="ml-4 mt-12">
                            <b-col sm="12">
                              <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                <b-col sm="12">
                                  <b-form-group>
                                    <template #label>
                                      ESPECIFICACIONES
                                    </template>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Código
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.codigo"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col> 
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Descripción
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.descripcion"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Comentarios
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.comentarios"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Cantidad
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.no_piezas"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group label-for="largo_solicitado">
                                    <template #label>
                                      Costo unitario
                                    </template>
                                    <b-input-group size="sm" prepend="$">
                                      <b-form-input
                                        disabled
                                        v-model="item_producto_terminado.costo_unitario"
                                        size="sm"
                                      ></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group label-for="largo_solicitado" prepend="$">
                                    <template #label>
                                      Importe
                                    </template>
                                    <b-input-group size="sm" prepend="">
                                      <b-form-input
                                        disabled
                                        input-group-text="$"
                                        v-model="item_producto_terminado.importe"
                                        size="sm"
                                      ></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <br><br>
                              </b-row>
                              <br><br>
                              <br><br>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
              <!-- === FIN DE PRODUCTO TERMINADO === -->
            </b-col>
          </b-row>
					<b-row class="mt-2">
						<b-col sm="12" class="rounded border p-4 pt-0">
              <div>
                <h4>Diseños y archivos a imprimir</h4>
                <div class="file-container">
                  <table class="table w-100">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Nombre</th>
                        <th scope="col" colspan="2">Acciones</th>
                        <th scope="col" colspan="2">Seleccionar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(file, index) in files" :key="file.id">
                        <th scope="row">{{index+1}}</th>
                        <td class="text-break">{{file.name}}</td>
                        <td><a :href="`${AppBaseUrl}${file.path}`" target="_blanc" class="btn btn-info">Ver</a></td>
                        <td><input type="checkbox" :value="file.id" v-model="selectedFiles" v-if="(/\.(jpe?g|png)$/i).test(file.path)" /> {{ (/\.(jpe?g|png)$/i).test(file.path) ? 'Seleccionar' : 'Solo se permiten imágenes' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-col>
					</b-row>
        </div>
        <div class="block-content font-size-sm">
					<b-row>
						<b-col sm="12">
							<h5 class="text-center"><small>Inspección de primera pieza</small></h5>
						</b-col>
						<b-col sm="4">
							<b-form-group label-for="no_cliente">
								<template #label>
									Órden de producción: <span class="text-danger">*</span>
								</template>
								<b-form-input
									value="1"
									size="sm"
									disabled
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="3">
							<b-form-group label-for="no_cliente">
								<template #label>
									Fecha: <span class="text-danger">*</span>
								</template>
								<b-form-datepicker
									id="datepicker"
									v-model="quoteInfo.inspection_sheet.fecha"
									placeholder=""
									class="mb-2"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  disabled
								>
								</b-form-datepicker>
							</b-form-group>
						</b-col>
						<b-col sm="4" class="mt-2">
							<b-form-group label-for="no_cliente">
								<template #label>
									Proceso: <span class="text-danger">*</span>
								</template>
								<select v-model="quoteInfo.inspection_sheet.proceso" class="form-control" disabled>
									<!--<option value="1">SERVICIO</option>-->
									<option value="LÁSER">LÁSER</option>
									<option value="PLASMA">PLASMA</option>
									<option value="OXICORTE">OXICORTE</option>
								</select>
							</b-form-group>
						</b-col>
						<b-col sm="4" class="mt-2">
							<b-form-group label-for="no_cliente">
								<template #label>
									EQMED: <span class="text-danger">*</span>
								</template>
								<b-form-input
									v-model="quoteInfo.inspection_sheet.eqmed"
									size="sm"
                  disabled
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="4" class="mt-2">
							<b-form-group label-for="no_cliente">
								<template #label>
									Responsable: <span class="text-danger">*</span>
								</template>
								<b-form-input
									v-model="quoteInfo.inspection_sheet.responsable"
									size="sm"
                  disabled
								></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<template v-if="true" >
              <div class="rounded border mt-3 p-3 label-small" v-for="(item_producto_terminado, index_Q_F_P) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id">
                <b-row>
                  <b-col sm="1">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Partida:
                      </template>
                        <b-form-input
                          :value="`${index_Q_F_P+1}`"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        Piezas:
                      </template>
                        <b-form-input
                          size="sm"
                          disabled
                          v-model="item_producto_terminado.cantidad"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Descripción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          size="sm"
                          v-model="item_producto_terminado.isqfp_inspection.descripcion"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1" v-if="typeof item_producto_terminado.finished_product.calibre !== 'undefined' && item_producto_terminado.finished_product.calibre !== '' && parseFloat(item_producto_terminado.finished_product.calibre) > 0">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Espesor: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.finished_product.calibre"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1" v-if="typeof item_producto_terminado.finished_product.calibre === 'undefined' || item_producto_terminado.finished_product.calibre === '' || item_producto_terminado.finished_product.calibre == null || parseFloat(item_producto_terminado.finished_product.calibre) <= 0">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Espesor: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.isqfp_inspection.espesor"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Real: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.isqfp_inspection.real"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        # de inspecciones: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          value="1"
                          size="sm"
                          v-model="item_producto_terminado.isqfp_inspection.no_inspecciones"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        Cantidad a inspeccionar: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          value="1"
                          size="sm"
                          v-model="item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3" append="%">
                      <template #label>
                        Muestreo:
                      </template>
                        <b-input-group size="sm" append="%">
                          <b-form-input
                            size="xs"
                            disabled
                            :value="item_producto_terminado.isqfp_inspection.muestreo = ((item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar *100) / item_producto_terminado.cantidad).toFixed(2)"
                            v-model="item_producto_terminado.isqfp_inspection.muestreo"
                          ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2 p-3">
                  <b-col sm="12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Tolerancia</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.tolerancia"
                                    size="sm"
                                    disabled
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Espec</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.espec"
                                    size="sm"
                                    disabled
                                  ></b-form-input>
                              </b-form-group>
                            </td>                          
                          </tr>
                          <tr>
                            <th scope="row">MAX</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.max"
                                    size="sm"
                                    disabled
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">MIN</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.min"
                                    size="sm"
                                    disabled
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2 p3">
                  <p style="border: none!important; width: 100%; margin-bottom: 10px!important; text-align: center;">DIMENSIÓN REAL</p>
                  <b-col sm="12">
                    <table class="table">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                      <tbody>
                        <tr v-for="(real_dimension, index) in item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar" :key="index">
                          <th scope="row">{{ index+1 }}</th>
                          <td v-for="(inspection_item, index_item) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_item">
                            <b-form-group v-for="(inspection_rd_item, index_rd_item) in inspection_item.real_dimensions" :key="index_rd_item">
                              <b-form-input
                                v-if="index_rd_item == index"
                                v-model="inspection_rd_item.valor"
                                size="sm"
                                disabled
                              ></b-form-input>
                            </b-form-group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </div>
            </template>
					</b-row>
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" class="ml-1" @click="SaveAndPrintPaper()">Imprimir Hoja(s)</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>
  import Api from "../../../api/Api"
  import Clientes from "../../../api/admin/clientes/Clientes";
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
  import Sucursal from "../../../api/admin/ubicacion/Sucursales"
  import Almacen from "../../../api/admin/ubicacion/Almacenes"
  import Archivos from '../../../api/admin/archivos/Archivos';

  import Swal from "sweetalert2";

  export default {

    data() {
      return {
        quote_id:0,
        hostEndPointURL:Api.defaults.appBaseURL,
        quoteInfo:{client:[],service_order:[],production_order:[], inspection_sheet:[]},
        sucursalesList:[],
        almacenesList:[],
        form:[],
        files:[],
        selected_list_items_producto_terminado:[],
        selectedFiles:[],
        AppBaseUrl:null
      }
    },

    methods: {

      Cancelar(){
        this.$router.push({
          path: "/cotizacion/list"
        });
      },

      GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          context.selected_list_items_producto_terminado = responseQuoteInfo.quote_finished_products
          context.selected_list_items_producto_terminado.forEach((element) => {
            if(element.isqfp_inspection == null){
              element.isqfp_inspection = {
                id : 0,
                descripcion : '',
                espesor : 0.0,
                real : 0.0,
                no_inspecciones : 0,
                cantidad_a_inspeccionar : 0,
                muestreo : 0.0,
                q_f_p_id : element.id
              }
            }
          })
          context.files = responseQuoteInfo.files
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      AllClientes() {
        let auth = JSON.parse(localStorage.autentication);
        Clientes.GetAllCustomers(auth)
        .then((response) => {
          this.clientes = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllSucursales(){
        let auth = JSON.parse(localStorage.autentication);
        Sucursal.GetAllSucursales(auth)
        .then((response) => {
          this.sucursalesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllAlmacenes(){
        let auth = JSON.parse(localStorage.autentication);
        Almacen.GetAllAlmacenes(auth)
        .then((response) => {
          this.almacenesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      SaveAndPrintPaper(){
				const context = this
        let files_selected = context.selectedFiles.join('|')
        console.log(`${context.hostEndPointURL}prints/pdf/${context.quote_id}/${files_selected}`)
				window.open(`${context.hostEndPointURL}prints/pdf/${context.quote_id}/${files_selected}`, '_blank');
      },
    },   

    mounted() {
      let me = this
      me.quote_id = me.$route.params.id
      me.AppBaseUrl = Archivos.AppBaseUrl
      me.AllClientes()
      me.AllSucursales()
      me.AllAlmacenes()
      me.GetQuoteInfo(me.quote_id);
    }
  };



</script>


<style scoped>
    #button_accordion{
      background: #272e38;
      border-color: #272e38;
    }

    #titulo {
      text-align: center;
    }

    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .v-line{
      border-left: solid #e8dbdb;
      height: 57%;
      left: 63%;
      position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>